import Styled from "styled-components";

export const PoliciesWrapper = Styled.div`
    max-width: 80%;
    margin: 5% auto;

    @media only screen and (max-width: 992px) {
      max-width:none;
      width:95%;
      h1{
      font-size:7vw;
    }
      h2{
        font-size:1.5em;
        text-align:center;
      }
      p{
        font-size:0.9em;
      }
    }
`;

export const Container = Styled.div`
    border: 1px solid #333;
`;

export const ContentDiv = Styled.div`
    display: ${props => (props.vision ? "block" : "none")};
    color: ${props => (props.vision ? null : "#333")};
    width:95%;
    margin:2% auto;
`;
export const PolicyHead = Styled.div`
    background: ${props => (props.vision ? "#181818" : "#f8f8f8")};
    color: ${props => (props.vision ? "#f8f8f8" : "#333")};
    box-sizing: border-box;
    margin:0;
    cursor: pointer;
    display:flex;
    justify-content: space-between;
    align-items:center;


    :hover {
      background: #181818;
      color:#f8f8f8;

    }
 p{
    margin:0;
    padding: 5px;
    font-family: StureplansgruppenUnderRubrik;
 }

svg {
  margin-right:10px;
  font-size: 25px;
}
`;

import React, { useState } from "react";
import * as Styles from "../../css/policiesStyles";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Policy = ({ name, content }) => {
  const [visual, setVisual] = useState(false);

  return (
    <Styles.Container vision={visual}>
      <Styles.PolicyHead vision={visual} onClick={() => setVisual(!visual)}>
        <p>
          <strong>{name}</strong>
        </p>
        <FontAwesomeIcon icon={visual ? faAngleUp : faAngleDown} />
      </Styles.PolicyHead>
      <Styles.ContentDiv vision={visual}>{content}</Styles.ContentDiv>
    </Styles.Container>
  );
};

export default Policy;
